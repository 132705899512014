<template>
  <en-table-layout :table-data="tableData.data">
    <template slot="toolbar">
      <el-button @click="exportAll" type="primary" size="small">
        导出全部
      </el-button>
      <span v-if="exportAllLoading">提示：正在导出<span id="spinner">...</span></span>
      <div class="col"></div>

      <el-select v-model="params.opt_type" placeholder="请选择" clearable style="width:150px;margin-right: 20px;"
        size="small" @change="search">
        <el-option label="全部状态" value=""></el-option>
        <el-option label="激活" :value="1"></el-option>
        <el-option label="禁用" :value="0"></el-option>
        <el-option label="启用" :value="3"></el-option>
        <el-option label="延期" :value="2"></el-option>
      </el-select>

      <el-input style="width: 220px" size="small" clearable v-model="params.keyword"
        placeholder="请输入卡号或卡密进行搜索"></el-input>

      <el-button class="ml-3" @click="search" type="primary" size="small">
        搜索
      </el-button>

    </template>

    <template slot="table-columns">
      <el-table-column :key="'code'" label="编号" width="50" fixed="left" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ code(scope.$index, params) }}
        </template>
      </el-table-column>

      <el-table-column prop="exchange_time" label="操作时间" width="150" fixed="left" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <template>
            {{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm") }}
          </template>
        </template>
      </el-table-column>

      <el-table-column :key="'state'" prop="state" label="操作类型" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.opt_type |switch }}
        </template>
      </el-table-column>

      <el-table-column :key="'card_code'" prop="card_code" label="卡号" width="200"
        :show-overflow-tooltip="true"></el-table-column>

      <el-table-column :key="'card_key'" prop="card_key" label="卡密" width="200"
        :show-overflow-tooltip="true"></el-table-column>

      <el-table-column :key="'card_name'" prop="card_name" label="卡型" :show-overflow-tooltip="true"></el-table-column>

      <el-table-column prop="sales_company" label="销售公司" width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.sales_company ? scope.row.sales_company : '————' }}
        </template>
      </el-table-column>

      <el-table-column prop="sales_name" label="业务员" width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.sales_name ? scope.row.sales_name : '————' }}
        </template>
      </el-table-column>

      <el-table-column prop="binding_time" label="操作ip地址" width="250" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <template>
            {{ scope.row.opt_ip }}
          </template>
        </template>
      </el-table-column>
    </template>

    <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
      :page-size="tableData.page_size" :total="tableData.data_total">
    </el-pagination>
  </en-table-layout>
</template>

<script>
import * as API_Log from "@/api/combo-card-shop/card-log.js";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import mixin from "./components/mixin";

export default {
  name: "cardGrantOnlineGrant",
  components: {
    EnTableLayout,
  },
  mixins: [mixin],
  data () {
    return {
      params: {
        card_id: this.$route.query.card_id,//卡券id
        type: this.$route.query.type, // 1 在线发放 2 卡密管理
        cards_log_id: this.$route.query.cards_log_id,//批次id            
        opt_type: "", // 状态；0：未激活，1：已激活，2已禁用，3已兑换，4已过期
        keyword: "",
        page_no: 1,
        page_size: 20,
      },
      is_all: 0,
      idsList: [],
      tableData: {},
      exportAllLoading: false,
    };
  },
  filters: {
    switch (status) {
      const statusMap = {
        1: "激活",
        3: "启用",
        0: "禁用",
        2: "延期",
      };
      return statusMap[status];
    },
  },
  mounted () {
    this.Get_TableData();
  },
  methods: {
    /**
     * 导出全部
     */
    exportAll () {
      this.exportAllLoading = true;
      let _params = { ...this.params };
      API_Log.exportOut(_params)
        .then((res) => {
          this.save(res);
        })
        .finally((_) => {
          this.exportAllLoading = false;
        });
    },
    /**
     * 获取列表数据
     */
    Get_TableData () {
      API_Log.comboPage(this.params).then((res) => {
        this.tableData = res;
      });
    },
  },
};
</script>

<style lang="scss"></style>
